<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>Filters</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="filters.distributor_id"
            placeholder="Distributor"
            :items="distributorOptions"
            item-text="title"
            item-value="value"
            dense
            outlined
            clearable
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="3"
          md="2"
        >
          <v-select
            v-model="filters.status"
            placeholder="Status"
            :items="statuses"
            item-text="title"
            item-value="value"
            dense
            outlined
            clearable
            hide-details
          ></v-select>
        </v-col>
      </v-row>
    </v-card>

    <v-card>
      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="search"
            label="Search by card number, first name, last name or phone number"
            outlined
            hide-details
            dense
            clearable
            class="me-3 mb-4"
          />
        </v-col>

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          class="mb-4 me-3"
          :loading="loading"
          :disabled="loading"
          @click="loadCards"
        >
          <v-icon
            size="17"
            class="me-1"
          >
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>Refresh</span>
        </v-btn>

        <import-dialog
          :distributor-id="filters.distributor_id"
          :distributors="distributorOptions"
          @imported="loadCards"
        />

        <v-btn
          color="success"
          outlined
          class="mb-4"
          :loading="loading"
          :disabled="loading"
          @click="exportToXlsx"
        >
          <v-icon
            size="17"
            class="me-1"
          >
            {{ icons.mdiDatabaseExportOutline }}
          </v-icon>
          <span>Export</span>
        </v-btn>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="tableItems"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
      >
        <!-- main -->
        <template #[`item.main`]="{ item }">
          <span class="text-no-wrap">{{ item.main ? 'Yes' : 'No' }}</span>
        </template>

        <!-- register date -->
        <template #[`item.registered_at`]="{ item }">
          <span class="text-no-wrap">{{ dayjs(item.registered_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>

        <!-- activate date -->
        <template #[`item.activated_at`]="{ item }">
          <span class="text-no-wrap">{{ dayjs(item.activated_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>

        <!-- expiry date -->
        <template #[`item.expired_at`]="{ item }">
          <span class="text-no-wrap">{{ dayjs(item.expired_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="setAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <form-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="
        loadCards()
        aside = false
      "
    ></form-aside>
  </div>
</template>

<script>
import {
mdiDatabaseExportOutline,
mdiDeleteOutline, mdiDotsVertical, mdiPencilOutline, mdiRefresh,
} from '@mdi/js'

import { useDistributor } from '@/composables'
import FormAside from '@/modules/medical/components/FormAside'
import ImportDialog from '@/modules/medical/components/ImportDialog'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import useCardList from './useCardList'

export default {
  components: { FormAside, ImportDialog },

  setup() {
    const {
      tableItems, tableColumns, tableTotal, options, filters, search, loading, loadCards, exportToXlsx,
    } = useCardList()

    const actions = ['Edit']

    const aside = ref(false)
    const setAside = item => {
      aside.value = true
      resource.value = item
    }

    const selectedAction = ref('')
    const resource = ref(null)

    const { distributorOptions } = useDistributor()

    const statuses = ref([
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
      { title: 'Expired', value: 'expired' },
      { title: 'Suspended', value: 'suspended' },
      { title: 'Cancelled', value: 'cancelled' },
      { title: 'Voided', value: 'voided' },
    ])

    return {
      loading,
      t,
      formatDate,
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiRefresh,
        mdiDatabaseExportOutline,
      },

      // filter
      distributorOptions,
      statuses,

      setAside,
      aside,
      resource,

      tableColumns,
      tableItems,
      tableTotal,
      options,
      filters,
      search,

      actions,
      selectedAction,

      loadCards,
      exportToXlsx,

      dayjs,
    }
  },
}
</script>

<style lang="scss" scoped></style>
